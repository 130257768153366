/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/**
 * Methods logic extracted from the ClientJS library
 * https://github.com/jackspirou/clientjs/blob/d7584e10a4e45a4a96d712cd4292465aeedc0218/src/client.js#L338
 */
import { isClient } from './utils/isClient';
var checkUserAgentProperty = function () { var _a; return isClient() && ((_a = window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent); };
export var isTouchDevice = function () { return !!(isClient() && 'ontouchstart' in window); };
export var isAndroidDevice = function () {
    return checkUserAgentProperty() && !!window.navigator.userAgent.match(/Android/i);
};
export var isIOSDevice = function () {
    return checkUserAgentProperty() &&
        !!window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
};
export var isSafari = function () {
    return checkUserAgentProperty() &&
        Boolean(window.navigator.userAgent.match(/^((?!chrome|android).)*safari/i));
};
