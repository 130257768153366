var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { display, width, space, alignItems, maxWidth, fontSize, lineHeight, } from 'styled-system';
import { boxSizing } from '../../../common/theme/system-utilities';
export var ListItem = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, width, space, alignItems, maxWidth, boxSizing, fontSize, lineHeight);
ListItem.defaultProps = {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '100%',
    boxSizing: 'border-box',
    fontSize: ['fs_14', null, null, null],
    lineHeight: ['lh_20', null, null, null],
};
var templateObject_1;
