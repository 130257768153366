var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from '@emotion/styled';
import Separator from '../../common/components/Separator';
var StyledSeparator = styled(Separator)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
StyledSeparator.defaultProps = __assign(__assign({}, Separator.defaultProps), { display: ['none', null, 'block'] });
export default StyledSeparator;
var templateObject_1;
