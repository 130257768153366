var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useAccordion } from '../../common/hooks/accordion';
import { useMatchesViewport } from '../../common/hooks/media';
import { SectionName } from './enums';
var DEFAULT_EXPANDED_SECTIONS = [SectionName.General];
var useIsCollapsible = function () {
    var matchesDesktop = useMatchesViewport().matches.matchesDesktop;
    var isCollapsible = !matchesDesktop;
    return isCollapsible;
};
export var useFooterLinkSectionAccordion = function () {
    var isCollapsible = useIsCollapsible();
    var _a = __read(useAccordion(isCollapsible, DEFAULT_EXPANDED_SECTIONS), 2), toggle = _a[0], isExpanded = _a[1];
    return {
        toggle: toggle,
        isExpanded: isExpanded,
        isCollapsible: isCollapsible,
    };
};
