var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import Item from './Item';
import LinkSectionListItemImage from './LinkSectionListItemImage';
import { ListItem } from './ListItem';
var StyledListItem = styled(ListItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
StyledListItem.defaultProps = __assign(__assign({}, ListItem.defaultProps), { pr: 'sp_16', py: 'sp_8', width: 'auto' });
var ImageLinkSectionListItem = function (_a) {
    var index = _a.index, item = _a.item, isInline = _a.isInline;
    return (React.createElement(StyledListItem, __assign({ "data-testid": "link-section-list-item-".concat(index) }, (isInline && { py: ['sp_8', 'sp_0'] })),
        React.createElement(Item, { item: item },
            React.createElement(LinkSectionListItemImage, __assign({}, item.image, { alt: item.name })))));
};
export default ImageLinkSectionListItem;
var templateObject_1;
