var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { width, display, color, fontWeight } from 'styled-system';
import { Link } from '../../../common/components/Link';
import { Text } from '../../../common/components/Text';
import { textDecoration } from '../../../common/theme/system-utilities';
import { getTrackerProps } from '../../../common/tracking/getTrackerProps';
var ItemLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    ", ";\n  }\n\n  &:active {\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    ", ";\n  }\n\n  &:active {\n    ", ";\n  }\n"])), display, color, width, textDecoration, textDecoration, fontWeight);
ItemLink.defaultProps = {
    display: 'block',
    width: 1,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'bold',
    color: 'sys.neutral.text.strong',
    textDecoration: 'none',
};
var ItemText = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
ItemText.defaultProps = __assign(__assign({}, Text.defaultProps), { width: 1, fontSize: 'inherit', lineHeight: 'inherit', color: 'sys.neutral.text.strong' });
var getItemTrackerProps = function (_a) {
    var href = _a.href, title = _a.title, target = _a.target, label = _a.name, image = _a.image;
    return getTrackerProps(__assign(__assign({ 'data-testid': 'link-section-link', href: href, title: title, target: target }, (target === '_blank' && {
        rel: 'noreferrer',
    })), { onClick: function (event) {
            // Prevent tracking if the element is not an anchor or image
            event.nativeEvent.trackingPreventDefault =
                event.currentTarget.nodeName !== 'A';
        } }), __assign(__assign({ event: 'footerTracking' }, (!!image && { eventCategory: 'image' })), { eventData: __assign({ placement: 'linkSection', labelIdentifier: 'h24_footer_link_section' }, (!!image && { label: label })) }));
};
var Item = function (_a) {
    var item = _a.item, children = _a.children;
    var ItemComponent = !!item.href ? ItemLink : ItemText;
    return (React.createElement(ItemComponent, __assign({}, getItemTrackerProps(item)), children));
};
export default Item;
var templateObject_1, templateObject_2;
