import React from 'react';
import { Grid, GridItem } from '../../common/components/Grid';
import LinkSectionGroup from './LinkSectionGroup';
import Separator from './Separator';
import { SECTIONS_WITH_BORDERS } from './constants';
import { useFooterLinkSectionAccordion } from './hooks';
var FooterLinkSectionContents = function (_a) {
    var sections = _a.sections;
    var _b = useFooterLinkSectionAccordion(), toggle = _b.toggle, isExpanded = _b.isExpanded, isCollapsible = _b.isCollapsible;
    return (React.createElement(React.Fragment, null,
        React.createElement(Separator, null),
        React.createElement(Grid, { pt: [null, null, 'sp_12', 'sp_16'] }, sections.map(function (_a) {
            var key = _a.key, data = _a.data, width = _a.width;
            return (React.createElement(GridItem, { w: width, key: key },
                SECTIONS_WITH_BORDERS.includes(key) && React.createElement(Separator, null),
                React.createElement(LinkSectionGroup, { data: data, sectionName: key, isExpanded: isExpanded(key), isCollapsible: isCollapsible, onTitleClick: toggle })));
        }))));
};
export default FooterLinkSectionContents;
