var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import AccordionSection from '../../common/components/Accordion/AccordionSection';
import LinkSectionList from './LinkSectionList';
import { SectionName } from './enums';
var LinkSectionGroup = function (_a) {
    var _b = _a.data, title = _b.title, items = _b.items, isExpanded = _a.isExpanded, isCollapsible = _a.isCollapsible, isInline = _a.isInline, onTitleClick = _a.onTitleClick, sectionName = _a.sectionName;
    var hasBorderTop = sectionName === SectionName.General;
    return (React.createElement(AccordionSection, __assign({ displayBorder: ['block', null, 'none'], hasTopBorder: hasBorderTop, hasBottomBorder: !isInline, title: title, sectionName: sectionName, onTitleClick: onTitleClick, isCollapsible: isCollapsible, isExpanded: isExpanded }, (isInline && { displayTitle: 'none' })),
        React.createElement(LinkSectionList, { items: items, isOpen: isExpanded, isInline: isInline })));
};
export default LinkSectionGroup;
