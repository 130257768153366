var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import Item from './Item';
import { ListItem } from './ListItem';
var StyledListItem = styled(ListItem)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
StyledListItem.defaultProps = __assign(__assign({}, ListItem.defaultProps), { pr: ['sp_16', 'sp_24', null, 'sp_48'], py: 'sp_4', width: 'auto', fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_20', null, null, 'lh_24'] });
var InlineTextLinkSectionListItem = function (_a) {
    var index = _a.index, item = _a.item;
    return (React.createElement(StyledListItem, { "data-testid": "link-section-list-item-".concat(index) },
        React.createElement(Item, { item: item },
            React.createElement("span", { "data-testid": "link-section-link-label" }, item.name))));
};
export default InlineTextLinkSectionListItem;
var templateObject_1;
