import React from 'react';
import noop from 'lodash/noop';
import { Grid, GridItem } from '../../common/components/Grid';
import ReCaptchaDisclaimer from '../../common/components/ReCaptchaDisclaimer';
import LinkSectionGroup from './LinkSectionGroup';
import { SectionName } from './enums';
var GeneralOnlyFooterLinkSection = function (_a) {
    var section = _a.section;
    return (React.createElement(Grid, { pt: [null, null, 'sp_16'] },
        React.createElement(GridItem, { pb: "sp_16" },
            React.createElement(LinkSectionGroup, { data: section, sectionName: SectionName.General, onTitleClick: noop, isCollapsible: false, isExpanded: true, isInline: true }),
            React.createElement(ReCaptchaDisclaimer, null))));
};
export default GeneralOnlyFooterLinkSection;
