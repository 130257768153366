var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import FooterLinkSectionContents from './FooterLinkSectionContents';
import GeneralOnlyFooterLinkSection from './GeneralOnlyFooterLinkSection';
import Separator from './Separator';
import { isGeneralOnly, getSections } from './utils';
var FooterLinkSection = function (_a) {
    var general = _a.general, help = _a.help, payment = _a.payment, international = _a.international, shipping = _a.shipping, social = _a.social, separatorStyles = _a.separatorStyles;
    var sections = getSections({
        general: general,
        help: help,
        payment: payment,
        international: international,
        shipping: shipping,
        social: social,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Separator, __assign({ display: "none" }, separatorStyles)),
        isGeneralOnly(sections) ? (React.createElement(GeneralOnlyFooterLinkSection, { section: sections[0].data })) : (React.createElement(FooterLinkSectionContents, { sections: sections }))));
};
export default FooterLinkSection;
