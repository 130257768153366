import { SectionName } from './enums';
var PRIMARY_SECTION_SIZE = [1, null, 1 / 2];
var SECONDARY_SECTION_SIZE = [1, null, 1 / 4];
export var getSections = function (_a) {
    var general = _a.general, help = _a.help, payment = _a.payment, international = _a.international, shipping = _a.shipping, social = _a.social;
    return [
        { key: SectionName.General, data: general, width: PRIMARY_SECTION_SIZE },
        { key: SectionName.Help, data: help, width: PRIMARY_SECTION_SIZE },
        {
            key: SectionName.Payment,
            data: payment,
            width: SECONDARY_SECTION_SIZE,
        },
        {
            key: SectionName.Shipping,
            data: shipping,
            width: SECONDARY_SECTION_SIZE,
        },
        {
            key: SectionName.International,
            data: international,
            width: SECONDARY_SECTION_SIZE,
        },
        { key: SectionName.Social, data: social, width: SECONDARY_SECTION_SIZE },
    ].filter(function (linkSection) { return !!linkSection.data; });
};
export var isGeneralOnly = function (sections) {
    return sections.length === 1 && sections[0].key === SectionName.General;
};
export var hasImage = function (item) { return !!item.image; };
