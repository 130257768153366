var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { space, display, flexWrap, alignItems, justifyContent, } from 'styled-system';
import { listStyle } from '../../common/theme/system-utilities';
import LinkSectionListItem from './LinkSectionListItem';
var Ul = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, space, flexWrap, listStyle);
Ul.defaultProps = {
    px: 'sp_0',
    pt: ['sp_16', null, 'sp_0'],
    pb: ['sp_24', null, 'sp_24'],
    m: 'sp_0',
    mr: ['-16px', '-24px', null, '-32px'],
    flexWrap: 'wrap',
    listStyle: 'none',
};
var UlInline = styled(Ul)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), alignItems, justifyContent);
UlInline.defaultProps = __assign(__assign({}, Ul.defaultProps), { mr: ['-16px', '-24px', null, '-48px'], pb: 'sp_16', alignItems: 'center', justifyContent: 'center' });
var LinkSectionList = function (_a) {
    var items = _a.items, isOpen = _a.isOpen, _b = _a.isInline, isInline = _b === void 0 ? false : _b;
    var isAnyImage = items.some(function (item) { return !!item.image; });
    var List = isInline ? UlInline : Ul;
    return (React.createElement(List, __assign({ display: [isOpen ? 'flex' : 'none', null, 'flex'], "data-testid": "link-section-list" }, (isAnyImage && {
        mr: '-16px',
    })), items.map(function (item, index) { return (React.createElement(LinkSectionListItem, { key: index, index: index, item: item, isInline: isInline })); })));
};
export default memo(LinkSectionList);
var templateObject_1, templateObject_2;
