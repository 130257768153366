var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { display, height as heightUtil, maxWidth, width as widthUtil } from 'styled-system';
import { LazyImage } from '../../../common/components/LazyImage';
import { placeholderThumbnail } from '../../../common/utils/placeholderThumbnail';
var LinkSectionImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), widthUtil, heightUtil, display, maxWidth);
LinkSectionImage.defaultProps = {
    display: 'block',
    maxWidth: '100%',
};
var LinkSectionListItemImage = function (_a) {
    var width = _a.width, height = _a.height, src = _a.src, alt = _a.alt;
    return (React.createElement(LazyImage, { x1: src }, function (_a) {
        var loaded = _a.loaded, image = _a.image, innerRef = _a.innerRef;
        return (React.createElement(LinkSectionImage, { "data-testid": "link-section-image", width: width, height: height, alt: alt, ref: innerRef, src: loaded ? image : placeholderThumbnail(width, height) }));
    }));
};
export default LinkSectionListItemImage;
var templateObject_1;
